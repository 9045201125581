export const headerHeight = '3rem';

interface HeaderRoute {
    title: string;
    to: string;
}
export interface HeaderRoutes {
    title: string;
    to: string;
    children?: HeaderRoute[];
    outbound?: boolean;
}

// Child routes
const adminChildren: HeaderRoutes[] = [
    { to: '/admin/dashboard', title: 'Dashboard' },
    { to: '/admin/users', title: 'Users' },
    { to: '/admin/shows', title: 'Shows' },
    { to: '/admin/freemusic', title: 'Free Music' },
    { to: '/admin/coaching', title: 'Coaching' },
    { to: '/admin/companies', title: 'Organizations' },
];

const createChildren: HeaderRoutes[] = [
    { to: '/create/record', title: 'Record Audio' },
    { to: '/create/editor', title: 'Edit & Publish Episodes' },
    { to: '/content-calendar', title: 'Content Calendar'},
    { to: '/create/drafts', title: 'Drafts' },
];
// Disabled for now
// const myPodcastsChildren [{ key: 'My Podcasts', to: '/account/listener-support', title: 'Tip Jar' }],
const accountChildren: HeaderRoutes[] = [
    { to: '/account/personal', title: 'Personal Info' },
    { to: '/account/password', title: 'Password' },
    { to: '/account/orders', title: 'Orders' },
    { to: '/signout', title: 'Sign Out' },
];

export const adminRoutes: HeaderRoutes[] = [
    { to: '/my-podcasts', title: 'My Content' },
    { to: '/create/record', title: 'Create Content', children: createChildren },
    { to: '/analytics/show/1', title: 'Analytics' },
    { to: '/admin/users', title: 'Admin', children: adminChildren },
    { to: '/account/personal', title: 'Account', children: accountChildren },
];

export const signedIdRoutes: HeaderRoutes[] = [
    { to: '/my-podcasts', title: 'My Content' },
    { to: '/create/record', title: 'Create Content', children: createChildren },
    { to: '/analytics/show/1', title: 'Analytics' },
    { to: '/account/personal', title: 'Account', children: accountChildren },
];

export const signedOutRoutes: HeaderRoutes[] = [
    { to: '/signin', title: 'Sign In', outbound: true },
];
