interface Redirect {
    from: string;
    to: string;
}

export const redirects: Redirect[] = [
    { from: '/discover', to: '/' },
    { from: '/listen', to: '/' },
    { from: '/vip-extras', to: '/pricing' },
    { from: '/how-to-get-started', to: '/' }
];
