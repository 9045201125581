import {
    OPEN_EDIT_EPISODE_MODAL,
    CLOSE_EDIT_EPISODE_MODAL,
    UPDATE_EPISODE_FULFILLED,
    OPEN_IFRAME_MODAL,
    CLOSE_IFRAME_MODAL,
} from '../actions/episodeEdit'

const initialState = {
    guid: null,
    error: '',
    iframe: false,
    id: '',
}

export function editEpisodeForm(state = initialState, action) {
    switch (action.type) {
        case OPEN_EDIT_EPISODE_MODAL:
            return {
                ...state,
                guid: action.payload.guid,
            }
        case CLOSE_EDIT_EPISODE_MODAL:
        case UPDATE_EPISODE_FULFILLED:
            return initialState
        case OPEN_IFRAME_MODAL:
            return {

                iframe: true,
                id: action.payload.id,
            }
        case CLOSE_IFRAME_MODAL:
            return {

                iframe: false,
            }
        default:
            return state
    }
}
