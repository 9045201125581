import request from 'src/api/core'

const fetchExtrasDetails = () => request.get('vip_extras').then(({ data }) => data);

const fetchCouponDetails = ( coupon ) => request.post('vip_extras/coupon', coupon ).then(({data}) =>  data.newCoupon.percentOff);

const vipExtrasPayment = (token, paymentDetails) =>
    request
        .auth(token)
        .post('vip_extras/payment', paymentDetails)
        .then(({ data }) => data);

export const vipExtrasApi = {
    fetchExtrasDetails,
    fetchCouponDetails,
    vipExtrasPayment,
}
