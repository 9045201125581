import React, { useEffect, useState } from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { color } from 'src/styles/variables';

export const TrialCounterWrapper = styled.div({
    color: color.midGray,
    fontSize: '14px',
    padding: '5px 0',
    textDecoration: 'none',
    opacity: '80%',
    textAlign: 'center',
});

export const PriceLink = styled(Link)({
    color: color.accentPurple,
    textDecoration: 'none',
});

const TrialCounter = (props) => {
    const TrialDays = 7; // TODO: This should be calculated on the backend instead - z
    const [user, setUser] = useState({});
    const [trial, setTrial] = useState(0);

    useEffect(() => {
        if (props.isAuthenticated) {
            const userData = JSON.parse(localStorage.getItem('reduxPersist:auth'));
            setUser(userData.user);
        }
        const userDate = new Date(user.createdAt);
        const currentDate = new Date();
        let diff;
        if (user.pricing && user.planId === 'free') {
            if (Math.round((currentDate - userDate) / 86400000) >= TrialDays) {
                diff = 0;
                setTrial(diff);
                localStorage.setItem('trial', diff);
            } else {
                diff = Math.round(TrialDays - (currentDate - userDate) / 86400000);
                setTrial(diff);
                localStorage.setItem('trial', diff);
            }
        } else {
            localStorage.setItem('trial', 'paid');
        }
    });

    return (
        <>
            {user.pricing && user.planId === 'free' && (
                <TrialCounterWrapper>You have {trial} days left in your free trial. <a href="https://www.workperfectly.com/pricing">Upgrade now!</a></TrialCounterWrapper>
            )}
        </>
    );
};

export default TrialCounter;
