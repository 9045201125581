import { notification } from 'antd';

import * as pricingTexts from '../pricingTexts.json';

export const notLoggedInNotification = () =>
    notification['info']({
        message: pricingTexts.common.notifications.notLoggedInNotification.message,
        description: pricingTexts.common.notifications.notLoggedInNotification.description,
    });

export const subscribedNotifocation = () =>
    notification['success']({
        message: pricingTexts.common.notifications.subscribedNotifocation.message,
        description: pricingTexts.common.notifications.subscribedNotifocation.description,
    });

export const onSuccessfullTransactionNotification = () =>
    notification['success']({
        message: pricingTexts.common.notifications.onSuccessfullTransaction.message,
        description: pricingTexts.common.notifications.onSuccessfullTransaction.description,
        duration: 10,
    });

export const onSuccessfullAddCoupon = () =>
    notification['success']({
        message: pricingTexts.common.notifications.onSuccessfullCoupon.message,
        description: pricingTexts.common.notifications.onSuccessfullCoupon.description,
        duration: 10,
    });

export const rejectedPayment = () =>
    notification['error']({
        message: pricingTexts.common.notifications.rejectedPayment.message,
        description: pricingTexts.common.notifications.rejectedPayment.description,
        duration: 10,
    })

export const rejectedCoupon = () =>
    notification['error']({
        message: pricingTexts.common.notifications.rejectedCoupon.message,
        description: pricingTexts.common.notifications.rejectedCoupon.description,
        duration: 10,
    })
export const demoBookReject = () => {
    notification['error']({
        message:pricingTexts.common.notifications.demoBookReject.message,
        duration:5,
    })
}
export const demoBookAply = () => {
    notification['success']({
        message:pricingTexts.common.notifications.demoBookAply.message,
        duration: 10,
    })
}
