import ReactGA from 'react-ga4';
import { createBrowserHistory } from 'history';
export const history = createBrowserHistory();

ReactGA.initialize('G-N13QR6WKZ3');
ReactGA.set({ page: window.location.pathname });
ReactGA.send({
    hitType: 'pageview',
    page: window.location.pathname,
});

// Fire every time the location changes
history.listen(location => {
    const page = location.pathname;
    ReactGA.set({ page });
    ReactGA.send({
        hitType: 'pageview',
        page,
    });
});
