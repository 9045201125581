import React from 'react';
import styled from 'react-emotion';

import instagramIconBlack from 'src/public/img/instagram-black.svg';
import instagramIconWhite from 'src/assets/PNG/instagram.png';
import linkedInIconBlack from 'src/public/img/linked-in-black.svg';
import linkedInIconWhite from 'src/assets/PNG/linkedin.png';
import twitterIconBlack from 'src/public/img/twitter-black.svg';
import twitterIconWhite from 'src/assets/PNG/twitter.png';

interface Props {
    centered?: boolean;
    light?: boolean;
    smallHeader?: boolean;
}

const Links = styled.div<Props>(({ centered }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: centered ? 'center' : 'flex-start',
    margin: '0',
}));

const Link = styled.a({
    margin: '0 12px 12px',
    width: '1.5rem',
    height: '1.5rem',
});

export const Community: React.FC<Props> = ({ centered, light }) => {
    const communityItems = [
        {
            title: 'Instagram',
            iconSrc: light ? instagramIconWhite : instagramIconBlack,
            link: 'https://www.instagram.com/workperfectly/',
        },
        {
            title: 'LinkedIn',
            iconSrc: light ? linkedInIconWhite : linkedInIconBlack,
            link: 'https://www.linkedin.com/company/workperfectly',
        },
        {
            title: 'Twitter',
            iconSrc: light ? twitterIconWhite : twitterIconBlack,
            link: 'https://twitter.com/work_perfectly',
        },
    ];

    return (
        <>
            <Links centered={centered}>
                {communityItems.map(({ title, iconSrc, link }) => (
                    <Link key={title} href={link} target="_blank">
                        <img src={iconSrc} alt={title} height={'20px'} />
                    </Link>
                ))}
            </Links>
        </>
    );
};
