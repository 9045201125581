import React from 'react';

export const routes = [
    // React components
    {
        path: '/draft_podcast/:guid',
        component: React.lazy(async () => import('src/modules/draftPodcast')),
    },
    {
        path: '/mom',
        component: React.lazy(() => import('src/modules/MomHourPage/MomPage')),
        exact: true,
    },
    {
        path: '/subscribed',
        component: React.lazy(() => import('src/modules/old/Subscribed')),
        secured: true,
    },
    {
        path: '/show/:id',
        component: React.lazy(() => import('src/modules/Podcasts/Show/Show')),
    },
    {
        path: '/show-not-found',
        component: React.lazy(() => import('src/modules/Podcasts/Show/components/ShowNotFound')),
    },
    {
        path: '/podcast/:guid',
        component: React.lazy(() => import('src/modules/Podcasts/Episode/Episode')),
    },
    {
        path: '/create/record/:id?',
        component: React.lazy(() => import('src/modules/old/Create')),
        secured: true,
    },
    {
        path: '/create/(record|editor|drafts)?',
        component: React.lazy(() => import('src/modules/old/Create')),
        secured: true,
    },
    {
        path: '/my-podcasts',
        component: React.lazy(() => import('src/modules/MyPodcast')),
        secured: true,
    },
    {
        path: '/content-calendar',
        component: React.lazy(() => import('src/modules/ContentCalendar')),
        secured: true,
    },
    {
        path: '/profile/:profileId',
        component: React.lazy(() => import('src/modules/Profile/Profile')),
    },
    {
        path: '/signin',
        component: React.lazy(() => import('src/modules/Auth/containers/SignIn')),
        loggedInRedirect: true,
    },
    {
        path: '/join',
        component: React.lazy(() => import('src/modules/Auth/containers/SignUp')),
    },
    {
        path: '/pricing/success/:id',
        component: React.lazy(() => import('src/modules/Pricing/Success')),
        secured: true,
    },
    {
        path: '/checkout/:id',
        component: React.lazy(() => import('src/modules/Pricing/StripeCheckoutRedirect')),
        secured: true,
    },
    {
        path: '/pricing',
        component: React.lazy(() => import('src/modules/Pricing/Pricing')),
        secured: true,
    },
    {
        path: '/reset/:token?',
        component: React.lazy(() => import('src/modules/old/ResetPassword')),
        loggedInRedirect: true,
    },
    {
        path: '/verify/email/:token',
        component: React.lazy(() => import('src/modules/old/VerifyAccount')),
    },
    {
        path: '/verify',
        component: React.lazy(() => import('src/modules/old/VerifyAccount')),
    },
    {
        path: '/signout',
        component: React.lazy(() => import('src/modules/Auth/containers/SignOut')),
    },
    {
        path: '/account/(personal|password|billing)?',
        component: React.lazy(() => import('src/modules/old/Account')),
        secured: true,
    },
    {
        path: '/admin/dashboard',
        component: React.lazy(() => import('src/modules/old/Admin/Dashboard')),
        adminFeature: true,
    },
    {
        path: '/admin/coaching',
        component: React.lazy(() => import('src/modules/old/Admin/Coaching')),
        adminFeature: true,
    },
    // {
    //     path: '/admin/users/:id',
    //     component: React.lazy(() => import('src/modules/old/Admin/User')),
    //     adminFeature: true,
    // },
    {
        path: '/admin/shows/:showId/:episodeId',
        component: React.lazy(() => import('src/modules/old/Admin/Episode')),
        adminFeature: true,
    },
    {
        path: '/admin/shows/:id',
        component: React.lazy(() => import('src/modules/old/Admin/Show')),
        adminFeature: true,
    },
    {
        path: '/admin/ads/audio',
        component: React.lazy(() => import('src/modules/old/Admin/Show')),
        adminFeature: true,
    },
    {
        path: '/admin/shows',
        component: React.lazy(() => import('src/modules/old/Admin/Shows')),
        adminFeature: true,
    },
    {
        path: '/admin/ads/audio/:id',
        component: React.lazy(() => import('src/modules/old/Admin/AudioAd')),
        adminFeature: true,
    },
    {
        path: '/admin/ads/audio',
        component: React.lazy(() => import('src/modules/old/Admin/AudioAds')),
        adminFeature: true,
    },
    {
        path: '/admin/ads/visual/:id',
        component: React.lazy(() => import('src/modules/old/Admin/VisualAd')),
        adminFeature: true,
    },
    {
        path: '/admin/ads/visual',
        component: React.lazy(() => import('src/modules/old/Admin/VisualAds')),
        adminFeature: true,
    },
    {
        path: '/admin/freemusic/:id',
        component: React.lazy(() => import('src/modules/old/Admin/FreeMusic')),
        adminFeature: true,
    },
    {
        path: '/admin/freemusic',
        component: React.lazy(() => import('src/modules/old/Admin/FreeMusics')),
        adminFeature: true,
        exact: true,
    },
    //routes for companies
    {
        path: '/admin/companies',
        component: React.lazy(() => import('src/modules/old/Admin/Companies')),
        adminFeature: true,
        exact: true,
    },
    //routes for users of a company
    {
        path: '/admin/companies/:companyId/users',
        component: React.lazy(() => import('src/modules/old/Admin/Users')),
        adminFeature: true,
        exact: true,
    },
    {
        path: '/admin/users',
        component: React.lazy(() => import('src/modules/old/Admin/Users')),
        adminFeature: true,
    },
    //routes for the user info of a company
    {
        path: '/admin/companies/:companyId/users/:userId',
        component: React.lazy(() => import('src/modules/old/Admin/User')),
        adminFeature: true,
        exact: true,
    },
    {
        path: '/admin/users/:userId',
        component: React.lazy(() => import('src/modules/old/Admin/User')),
        adminFeature: true,
        exact: true,
    },
    {
        path: '/analytics/show/:show',
        component: React.lazy(() => import('src/modules/UserAnalytics')),
        secured: true,
        paidFeature: true,
    },
    /* {
        path: '/analytics/show/:show',
        component: React.lazy(() => import('src/modules/Analytics/containers/Analytics')),
        secured: true,
        paidFeature: true,
    }, */
    {
        path: '/privacy-policy',
        component: React.lazy(() => import('src/modules/Auth/containers/Privacy')),
    },
    {
        path: '/terms',
        component: React.lazy(() => import('src/modules/Auth/containers/Terms')),
    },
    {
        path: '/how-to-submit',
        component: React.lazy(() => import('src/modules/old/HowToSubmit')),
    },
    {
        path: '/contact',
        component: React.lazy(() => import('src/modules/old/Contact')),
    },
    {
        path: '/podcastideagenerator',
        component: React.lazy(() => import('src/modules/old/PodcastIdeaGenerator')),
    },
    {
        path: '/billing/finalize-express',
        component: React.lazy(() => import('src/modules/old/StripeFinalize')),
    },
    {
        path: '/player',
        component: React.lazy(() => import('src/modules/Audio/containers/PlayerEmbed')),
    },
    // Any other URL can be a custom Podcast URL or not found
    // Could also add back the WP support later
    {
        path: '',
        component: React.lazy(async () => import('src/modules/Podcasts/Show/Show')),
    },
];

// All routes not on this list will load with a navbar / footer instead
// Used for HubSpot routes
const routesWithCleanDom = ['/player', '/podcast/'];
// Used for paths that have query strings / params
const routesWithCleanDomRegEx = ['/join', '/signin', '/player', '/podcast', '/checkout'];
function getPathFromUrl(url) {
    return url.split(/[?#]/)[0];
}
function matchStart(str) {
    let matches = false;
    routesWithCleanDomRegEx.forEach(el => {
        const re = new RegExp(`^${el}`);
        matches = re.test(getPathFromUrl(str));
    });
    return matches;
}
export function ShouldHaveFooter(str) {
    if (routesWithCleanDom.includes(str)) return true;
    if (matchStart(str)) return true;
    return false;
}
