import { Row } from 'antd';
import React from 'react';
import styled from 'react-emotion';

import logo from 'src/assets/messy-fm-site-logo.png';

import { Community } from 'src/modules/Auth/components/Community';
import { ContentWrapper, SectionWrapper } from 'src/styles/sections';
import { color, font, media } from 'src/styles/variables';

import mailIcon from 'src/assets/PNG/email_id.png';
import './style.css';

const siteLinkBaseStyle = {
    color: color.white,
    '&:hover': {
        color: color.white,
        textDecoration: 'underline',
    },
};

const FooterContentWrapper = styled(ContentWrapper)(
    {
        fontFamily: 'Open Sans, Arial, Helvetica, sans-serif',
        fontSize: '.8em',
        color: color.white,
        a: siteLinkBaseStyle,
    },
    font.light(font.size.base),
);

const FooterContent = styled.div({
    paddingLeft: '12px',
});

const Logo = styled.img({
    cursor: 'pointer',
    height: '30px',
    width: 'auto',
});
const ShortLine = styled.div({
    paddingBottom: '20px',
    borderBottom: '2px solid #302651',
    width: '200px',
});
const AccessibilityDiv = styled.div({
    fontStyle: 'italic',
    margin: '20px 0',
    fontSize: '15px',
});
const MailIcon = styled.img({
    marginRight: '12px',
});
const Bottom = styled.div(
    {
        display: 'block',
        padding: '2.4rem 0',
        borderTop: `2.4px solid #302651`,
        backgroundColor: color.navyBlue,
        color: 'rgba(255, 255, 255, 0.67)',
        fontSize: '18px',
        fontWeight: 200,
        width: '100%',
    },
    font.light(font.size.small),
);

const FooterRow = styled(Row)({
    justifyContent: 'space-between',
    flexDirection: 'column',
    [media.md]: {
        flexDirection: 'row',
    },
});

const Copyright = styled.div({
    display: 'block',
    textAlign: 'center',
});

const LinkOuter = styled.a(siteLinkBaseStyle, {
    display: 'flex',
    alignContent: 'center',
    marginLeft: 0,
    paddingTop: '20px',
    fontSize: '14px',
});

export const Footer: React.FunctionComponent = () => (
    <>
        <SectionWrapper backgroundColor={color.navyBlue}>
            <FooterContentWrapper>
                <FooterRow type="flex">
                    <FooterContent>
                        <Logo className="footer--logo" src={logo} alt="WorkPerfectly" />
                        <ShortLine />
                        <LinkOuter href="mailto:hello@workperfectly.com">
                            <MailIcon src={mailIcon} height={'20px'} alt={'mail'} />
                            hello@workperfectly.com
                        </LinkOuter>
                        <ShortLine />
                        <AccessibilityDiv>
                            Accessibility is important. WorkPerfectly’s software is fully WCAG 2.1 AA compliant.
                        </AccessibilityDiv>
                    </FooterContent>
                    <Community light />
                </FooterRow>
            </FooterContentWrapper>
        </SectionWrapper>
        <Bottom>
            <Copyright>
                Copyright © Messy Inc. 2024. All Rights Reserved.
                <a className="footer--link" href="https://www.workperfectly.com/terms">
                    Terms of Service.
                </a>
                <a className="footer--link" href="https://www.workperfectly.com/privacy-policy">
                    Privacy Policy.
                </a>
            </Copyright>
        </Bottom>
    </>
);
