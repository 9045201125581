import {
    ADD_TO_BASKET,
    ADD_COUPON,
    ADD_COUPON_FULFILLED,
    ADD_COUPON_REJECTED,
    REMOVE_FROM_BASKET,
    FETCH_SUBSCRIPTIONS_FULFILLED,
    PRICING_PAYMENT,
    PRICING_PAYMENT_FULFILLED,
    PRICING_PAYMENT_REJECTED,
    //ADD_COUPON_GET_DISCOUNT,
} from './pricingActions';
// import { fetchCurrentPlan } from 'src/shared/components/old/Billing/actions';
const initialState = {
    basketItems: [],
    availableSubscriptions: [],
    paymentStatus: {
        processing: false,
        fulfilled: false,
        rejected: false,
    },
    coupon: '',
    couponStatus:{
        processing: false,
        fulfilled: false,
        rejected: false,
    },
    discountInPercent: 0,
};

export const pricing = (state = initialState, { type, payload }) => {
    switch (type) {
            case ADD_TO_BASKET:
                const isItemAlreadyInBasket = !!state.basketItems.find(item => item.plan_id === payload.plan_id);

                return !isItemAlreadyInBasket ? { ...state, basketItems: [...state.basketItems, payload] } : { ...state };
            case ADD_COUPON:
                return {
                    ...state,
                    coupon: payload.coupon,
                    couponStatus: {...state.couponStatus, processing: true },
                };
            case ADD_COUPON_FULFILLED:
                let discount = 0;
                if(state.coupon === 'HITHA' || state.coupon === 'MOLLYSFRIEND' || state.coupon === 'SPARK' || state.coupon === 'TECHSTARS')
                {
                    discount = 25;
                }
                else if (state.coupon === 'COMCASTBUSINESS'){
                    discount = 35;
                }
                else {
                    discount = 50;
                }
                return {
                    ...state,
                    couponStatus: { ...state.couponStatus, processing: false, fulfilled: true },
                    discountInPercent: discount,
                };
            case ADD_COUPON_REJECTED:
                return {
                    ...state,
                    couponStatus: { ...state.couponStatus, processing: false, rejected: true },
                };

            case REMOVE_FROM_BASKET:
                return {
                    ...state,
                    basketItems: [...state.basketItems.filter(item => item.plan_id !== payload.plan_id)],
                };
            case FETCH_SUBSCRIPTIONS_FULFILLED:
                return { ...state, availableSubscriptions: payload };
            case PRICING_PAYMENT:
                return {
                    ...state,
                    paymentStatus: { ...state.paymentStatus, processing: true },
                };
            case PRICING_PAYMENT_FULFILLED:
                return {
                    ...state,
                    basketItems: [],
                    paymentStatus: { ...state.paymentStatus, processing: false, fulfilled: true }
                };
            case PRICING_PAYMENT_REJECTED:
                return {
                    ...state,
                    paymentStatus: { ...state.paymentStatus, processing: false, rejected: true },
                };
            default:
                return state;
    }
};
