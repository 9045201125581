import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTopContainer = props => {
    useEffect(() => {
        window && window.scrollTo(0, 0);
    }, []);
    return props.children;
};

export const ScrollToTop = withRouter(ScrollToTopContainer);
