import device from './device'
import uuidv1 from 'uuid/v1';
import PodcastApi from 'src/api/podcasts';
// Need to send event in api/podcasts...
// Listening sessions
const listeningSessions = []
// Last recorded event
// Used to send final event before closing the window
let lastRecordedEvent = {
	podcast_guid: null
}
const thisSession = uuidv1()

function createListeningSession(podcast_guid, token) {
	const session = {
		session: thisSession,
		guid: uuidv1(),
		podcast_guid,
		device
	}
	listeningSessions.push(session)
	sendEvent(session, token)
}
function returnListeningSession(podcast_guid, token) {
	// Check if a listening session already exists
	let listeningSession = null
	listeningSessions.forEach(session => {
		if (session.podcast_guid === podcast_guid) listeningSession = session
	})
	if (!listeningSession) createListeningSession(podcast_guid, token)
	return listeningSession
}

// Attempt to save data before closing page
document.addEventListener('visibilitychange', () => {
	if (!lastRecordedEvent.podcast_guid) return null
	const payload = {
		...lastRecordedEvent,
		event: {
			type: 'closed',
			time: window.audio.seek(),
			speed: 1
		}
	}
	if (document.visibilityState === 'hidden') {
		sendEvent(payload, null)
	}
})

function sendEvent(payload, token) {
	/*
		Not calling the API to send analytics to BE
		as Podcast Plays by Super Admin/Creator from
		workperfectly.com/my-podcasts should not be counted
	*/
	const addToAnalytics = window.location.pathname.startsWith('/my-podcasts') ? false : true;
	if (addToAnalytics) {
		try { PodcastApi.sendAnalyticsEvent(payload, token) }
		catch (err) { }
	}
	lastRecordedEvent = payload;
}


export function saveAnalyticsEvent(eventName, currentPodcast, token) {
	const podcast_guid = currentPodcast.guid
	let listeningSession = returnListeningSession(podcast_guid, token)

	if (!listeningSession) return null// We just created a listening session,
	//no need to do anything else
	//Generate play event

	const event = {
		type: eventName.toString(),
		time: window.audio.seek(),
		speed: 1 // can't be changed in this context
	}

	sendEvent({
		session: thisSession,
		...listeningSession,
		event
	}, token)
}