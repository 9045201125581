import React from 'react';
import styled from 'react-emotion';
import { useLocation } from 'react-router-dom';

import { PlayBar } from 'src/modules/Audio/containers/PlayBar';
import { color } from 'src/styles/variables';

import { Footer } from './Footer/Footer';
import { Header } from './Header/Header';
import './Layout.css';
import { headerHeight } from './models/layoutData';

export const LayoutWrapper = styled.div({
    alignItems: 'center',
    backgroundColor: color.wildSand,
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    width: '100%',
});

const HeaderWrapper = styled.div<{
    marginTop?: string;
}>`
    margin-top: ${(props) => props.marginTop};
`;

export const LayoutContents = styled.div({
    width: '100%',
});

export const Layout: React.FC = (props) => {
    const showPlayerWidget = window.location.pathname.includes('/player');
    const location = useLocation();
    return showPlayerWidget ? (
        <>{props.children}</>
    ) : (
        <LayoutWrapper>
            <HeaderWrapper marginTop={`${location.pathname === '/my-podcasts' ? '0' : headerHeight}`}>
                <Header />
            </HeaderWrapper>
            {props.children}
            <PlayBar />
            <Footer />
        </LayoutWrapper>
    );
};
