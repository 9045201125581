export default {
    navigation: '#0E2535',
    azure: '#059bff',
    badge: '#6c757d',
    lightBlueGrey: '#e2cfea',
    lightBlue: '#e3f2fd',
    coral: '#ff4040',
    paleLilac: '#d4dcff',
    periwinkle: '#7d83ff',
    lightTeal: '#84dcc6',
    error: '#eb1c26',
    black12: 'rgba(0, 0, 0, 0.12)',
    black38: 'rgba(0, 0, 0, 0.38)',
    black54: 'rgba(0, 0, 0, 0.54)',
    black87: 'rgba(0, 0, 0, 0.87)',
    duckEggBlue: '#e2fbf4',
    white: '#fff',
    white70: 'rgba(255, 255, 255, 0.7)',
    white38: 'rgba(255, 255, 255, 0.38)',
    white12: 'rgba(255, 255, 255, 0.12)',
    powderBlue: '#bbdefb',
    salmon: '#ff7d7d',
    amethyst: '#a06cd5',
    cerulean: '#0074c2',
    shamrockGreen: '#00c44b',
    tangerine: '#ff8f05',
    lightGray: '#f5f5f5',
    softPink: '#fedcdc',
    warning: '#F1D334',
    footer: '#f9f9f9',
    mainYellow: '#FFE500',
    darkBlue: '#0D66FB',
    mainGreen: '#1CE1C3',
    hotPink: '#FE58C3',
    electricViolet: '#8800F9',
    fromGradient: '#53b8b2',
    toGradient: '#1f5073',
    pictonBlue: '#26d7d5',
    layout: '#f1f1f1',
    goldenFizz: '#DCFF4A',
    transparent: 'transparent',
}
