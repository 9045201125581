import { Layout, Menu } from 'antd';
import React from 'react';
import styled from 'react-emotion';
import { NavLink } from 'react-router-dom';

const { SubMenu, Item } = Menu;

import container from 'src/containers/Shows/ShowEpisodes';
import { color, font, media } from 'src/styles/variables';

import { fetchShowEpisodes } from 'src/actions/old';
import { EpisodeModel, ShowModel } from 'src/modules/MyPodcast/models';

import { Hamburger } from 'src/modules/Layout/Header/Hamburger';
import { adminRoutes, HeaderRoutes, signedIdRoutes, signedOutRoutes } from '../models/layoutData';

export const MenuWrapper = styled.div({
    position: 'absolute',
    display: 'flex',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    justifyContent: 'end',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: color.white,
    color: color.accentPurple,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.2s',
    [media.lg]: {
        position: 'relative',
        width: 'fit-content',
        height: '100%',
        backgroundColor: 'transparent',
        flexDirection: 'row',
        top: 0,
        opacity: 1,
        visibility: 'visible',
    },
});

export const MobileWrapper = styled.div({
    position: 'relative',
    [media.lg]: {
        display: 'none',
    },
});

export const MenuItem = styled(Item)({
    paddingLeft: '16px !important',
});

export const MenuStyle = styled(Menu)({
    backgroundColor: color.white,
    border: `1px solid ${color.lightGray}`,
    [media.md]: {
        border: 'none',
    },
    '& > li': {
        transition: 'none !important',
    },
    '& > .ant-menu-item-active': {
        borderColor: `${color.faintBlue} !important`,
        borderWidth: '1px !important',
        color: `${color.highlightBlue} !important`,
    },
    '& > .ant-menu-submenu-active': {
        borderColor: `${color.faintBlue} !important`,
        borderWidth: '1px !important',
        color: `${color.highlightBlue} !important`,
    },
    '& > .ant-menu-item-selected': {
        borderColor: `transparent !important`,
        borderWidth: '1px !important',
        color: `${color.highlightBlue} !important`,
    },
    '& > .ant-menu-submenu-selected': {
        borderColor: `transparent !important`,
        borderWidth: '1px !important',
        color: `${color.highlightBlue} !important`,
    },
    '& > .ant-menu-item > a:focus': {
        paddingBottom: '0 !important',
    },
});

interface SiderProps {
    isSwitched: boolean;
    collapsedWidth: string;
}

export const Siders = styled.div<SiderProps>(({ isSwitched }) => ({
    position: 'absolute',
    flex: 'auto !important',
    minWidth: 'auto !important',
    width: 'auto !important',
    backgroundColor: color.white,
    color: color.black,
    top: '2.686rem',
    right: '-2rem',
    borderRight: 'none',
    zIndex: 1,
    display: isSwitched ? 'flex' : 'none',
    [media.md]: {
        right: '-4rem',
    },
    [media.lg]: {
        display: 'none',
    },
}));

const ReloadLink = styled.a({
    position: 'relative',
    textDecoration: 'none',
    margin: 0,
    padding: 0,
    color: `${color.black} !important`,
    fontSize: font.size.small,
    fontWeight: font.weight.normal,
    '&:hover': {
        color: `${color.highlightBlue} !important`,
        fontWeight: font.weight.medium,
    },
    '&:focus': {
        textDecoration: 'none',
        paddingBottom: '14px',
    },
});

export const Link = styled(NavLink)({
    position: 'relative',
    textDecoration: 'none',
    color: color.darkBlue,
    margin: '0 0 2rem',
    fontSize: font.size.small,
    fontWeight: "normal",
    '&:hover': {
        color: color.newGreen,
    },
    '&:focus': {
        textDecoration: 'none',
        paddingBottom: '14px',
    },
    [media.lg]: {
        margin: '0 1rem',
        '&:last-child': {
            marginRight: 0,
        },
    },
});

const TopLevelNavLink = styled(NavLink)({
    position: 'relative',
    textDecoration: 'none !important',
    color: `${color.darkBlue} !important`,
    fontSize: font.size.small,
    fontWeight: "normal",
    '&:hover': {
        color: `${color.newGreen} !important`,
    },
    '&:focus': {
        textDecoration: 'none',
        paddingBottom: '14px',
    },
});

interface State {
    isExpanded: boolean;
    isSwitched: boolean;
    userPodcasts: any;
}

interface Props {
    isAuthenticated: boolean;
    isAdmin: boolean;
    fetchShowEpisodes: typeof fetchShowEpisodes;
    shows: any;
    list: any;
    podcast: any;
    show: ShowModel;
    episodes: EpisodeModel;
    currentPlan: any;
    planId: string;
}

class TopMenu extends React.Component<Props, State> {
    public state: State = {
        isExpanded: false,
        isSwitched: false,
        userPodcasts: [],
    };

    public closeMenu = () => {
        this.setState({ isSwitched: false });
        this.setState({ isExpanded: false });
    };

    public switchSider = () => {
        this.setState((state) => ({ isSwitched: !state.isSwitched }));
        this.setState((state) => ({ isExpanded: !state.isExpanded }));
    };

    private renderLinks = (routes: HeaderRoutes[]) =>
        routes.map((route) =>
            route.children ? (
                <SubMenu
                    key={route.title}
                    title={
                        <Link key={route.title} to={route.to} onClick={this.closeMenu} data-name={route.title}>
                            {' '}
                            {route.title}{' '}
                        </Link>
                    }
                >
                    {route.children.map((item) => (
                        <MenuItem key={item.title}>
                            <Link key={item.title} to={item.to} onClick={this.closeMenu} data-name={item.title}>
                                {item.title}
                            </Link>
                        </MenuItem>
                    ))}
                </SubMenu>
            ) : (
                <Item key={route.title}>
                    {
                        /* This is to prevent the navbar from being rendered twice */
                        route.outbound ? (
                            <ReloadLink data-name={route.title} key={route.title} href={route.to}>
                                {route.title}
                            </ReloadLink>
                        ) : (
                            <TopLevelNavLink
                                key={route.title}
                                to={route.to}
                                onClick={this.closeMenu}
                                data-name={route.title}
                            >
                                {route.title}
                            </TopLevelNavLink>
                        )
                    }
                </Item>
            ),
        );

    public render(): JSX.Element {
        const { isAuthenticated, isAdmin } = this.props;

        return (
            <>
                <MenuWrapper>
                    <Layout>
                        <MenuStyle theme="dark" mode={'horizontal'}>
                            {this.renderLinks(
                                isAuthenticated ? (isAdmin ? adminRoutes : signedIdRoutes) : signedOutRoutes,
                            )}
                        </MenuStyle>
                    </Layout>
                </MenuWrapper>
                <MobileWrapper>
                    <Siders isSwitched={this.state.isSwitched} collapsedWidth="0">
                        <MenuStyle mode="inline">
                            {this.renderLinks(
                                isAuthenticated ? (isAdmin ? adminRoutes : signedIdRoutes) : signedOutRoutes,
                            )}
                        </MenuStyle>
                    </Siders>
                    <Hamburger onClick={this.switchSider} isExpanded={this.state.isExpanded} />
                </MobileWrapper>
            </>
        );
    }
}

export const Menus = container(TopMenu);
