import TrialCounter from 'src/modules/Layout/Header/Trial2';
import './Header.css';
import React from 'react';
import styled from 'react-emotion';
import { connect } from 'react-redux';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import logo from 'src/public/svg/navbar_logo.png';

import { fetchAudioAds } from 'src/actions/old/ads';
import { AppState } from 'src/config/appState';
import { getIsAuthenticatedUserAdmin, getUserLoginStatus } from 'src/modules/Auth/selectors/authSelectors';
import { color, media } from 'src/styles/variables';

import { headerHeight } from '../models/layoutData';
import { Menus } from './Menu';

export const HeaderWrapper = styled.div({
    fontFamily: 'Inter, Open Sans, Arial, Helvetica, sans-serif',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 100,
    backgroundColor: color.white,
    borderBottom: `1px solid ${color.lightGray}`,
});

export const HeaderContent = styled.div({
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: "75rem",
    padding: '0.85rem 1.1rem',
    margin: '0 auto',
});

export const LogoWrapper = styled(NavLink)({
    display: 'block',
    zIndex: 2,
});

export const Logo = styled.img({
    cursor: 'pointer',
    width: "200px"
});

interface StateProps {
    isAuthenticated: boolean;
    isAdmin: boolean;
}

interface ActionsProps {
    fetchAudioAds: typeof fetchAudioAds;
}

type Props = StateProps & ActionsProps & RouteComponentProps;

class HeaderContainer extends React.Component<Props> {
    componentDidMount(): void {
        this.props.fetchAudioAds();
    }

    render() {
        return (
            <>
                <HeaderWrapper className="header-wrapper">
                    <HeaderContent>
                        <div>
                            <a href="/">
                                <Logo src={logo} className="header-logo" alt="WorkPerfectly" />
                            </a>
                        </div>
                        <Menus isAuthenticated={this.props.isAuthenticated} isAdmin={this.props.isAdmin} />
                    </HeaderContent>
                    <TrialCounter isAuthenticated={this.props.isAuthenticated} />
                </HeaderWrapper>
            </>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isAuthenticated: getUserLoginStatus(state),
    isAdmin: getIsAuthenticatedUserAdmin(state),
});

export const Header = withRouter(
    connect(mapStateToProps, {
        fetchAudioAds,
    })(HeaderContainer),
);
