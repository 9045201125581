export function determinePlan(subscriptions = []) {

    //premium and basic subscriptions are old plans so we should get rid of them in the near future
    //the new ones are /influencer/brand

    const subscription =
        subscriptions.find(s => {
            return(
             s.planId.startsWith('npremium') || s.planId.startsWith('nenterprise')|| s.planId.startsWith('premium') || s.planId.startsWith('enterprise')
            );

        }) || {}


    let planType;

    if (subscription && !subscription.active) {
        return { planId: 'free' }
    } else if(subscription.planId.startsWith('npremium') || subscription.planId.startsWith('nenterprise')||subscription.planId.startsWith('premium') || subscription.planId.startsWith('enterprise')) {
         planType = subscription.planId.replace(/:\d+$/, '');
    } else {
         planType = subscription.planId.replace(/_\d+$/, '')
    }

    return {
        ...subscription,
        type:  planType,
    }
}

export function determineSubscriptions(subscriptions = []) {
    const embedPodcastSubscription = subscriptions.find(s => s.planId.startsWith('embed_podcast'))
    return {
        plan: determinePlan(subscriptions),
        isEmbedPodcastActive: embedPodcastSubscription ? embedPodcastSubscription.active : false,
        subscriptions,
    }
}
