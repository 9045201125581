import _isEmpty from 'lodash/isEmpty';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Router as ReactRouter, Switch } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import { PlayBar } from 'src/modules/Audio/containers/PlayBar';

import { AppState } from 'src/config/appState';
import { STRIPE_KEY } from 'src/config/settings';
import { fetchUser } from 'src/modules/Auth/actions/auth';
import { Layout } from 'src/modules/Layout/Layout';
import { Loading } from 'src/shared/components/old/Loading';
import { history } from 'src/shared/helpers/history';
import { planIdText } from 'src/shared/variables';

import { CustomRoute } from './CustomRoute';
import { redirects } from './redirects';
import { ShouldHaveFooter, routes } from './routes';
import { ScrollToTop } from './ScrollToTop';

const CleanLayout: React.FC = props => {
    return (
        <>
            {props.children}
            <PlayBar />
        </>
    );
};

interface StateProps {
    isUserInfoFetched?: boolean;
    isAuthenticated?: boolean;
    isAdmin?: boolean;
    hasPaidFeaturesAccess?: boolean;
}

interface ActionsProps {
    fetchUser: typeof fetchUser;
}

type Props = StateProps & ActionsProps;

export class RouterContainer extends React.Component<Props> {
    componentDidUpdate(): void {
        // componentDidUpdate (not didMount) for JWT token debugging purposes - otherwise getUser gets fired before rehydrate with old token
        this.props.fetchUser(true);
    }

    render(): JSX.Element {
        const useNewLayout = ShouldHaveFooter(window.location.pathname);
        const DynamicLayout = useNewLayout ? CleanLayout : Layout;
        return (
            <ReactRouter history={history}>
                <ScrollToTop>
                    <StripeProvider apiKey={STRIPE_KEY}>
                        <DynamicLayout>
                            <React.Suspense fallback={<Loading />}>
                                <Switch>
                                    {redirects.map(route => (
                                        <Redirect key={route.from} from={route.from} to={route.to} />
                                    ))}
                                    {routes.map(route => (
                                        <CustomRoute key={route.path} {...this.props} {...route} />
                                    ))}
                                </Switch>
                            </React.Suspense>
                        </DynamicLayout>
                    </StripeProvider>
                </ScrollToTop>
            </ReactRouter>
        );
    }
}

const mapStateToProps = (state: AppState) => ({
    isUserInfoFetched: !state.auth.isFetching && !_isEmpty(state.auth.user),
    isAuthenticated: !!state.auth.token,
    isAdmin: !!state.auth.user.admin,
    hasPaidFeaturesAccess:
        planIdText.test(state.auth.user.planId) ||
        state.billing.userSubscriptions.some((s: any) => planIdText.test(s.planId)),
});

export const Router = connect(
    mapStateToProps,
    { fetchUser },
)(RouterContainer);
