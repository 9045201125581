import React from 'react';

export const SVGGradient = () => (
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={colors.messyGreen} stopOpacity={1} />
        <stop offset="95%" stopColor={colors.gradients.green.stop} stopOpacity={1} />
    </linearGradient>
);

export const fonts = {
    size: {
        extraSmall: '0.8rem',
        small: '0.85rem',
        base: '1rem',
        medium: '1.25rem',
        mediumLarge: '1.5rem',
        large: '2rem',
        xLarge: '2.5rem',
        extraLarge: '3rem',
    },
};

export const colors = {
    messyBG: '#000',
    messyText: '#fff',
    messyGreen: '#19CCA3',
    messyLGreen: '#27EDC0',
    messyGrey: '#e9eff5',
    messyDGrey: '#d0d2d3',
    messyPurple: '#9a00ff',
    messyLBlue: '#b8eaff',
    messyBlue: '#0fbcff',
    messyDBlue: '#0d2547',
    positiveGreen: '#277c00',
    gradients: {
        green: {
            start: 'rgba(171,225,172,0.76)',
            stop: 'rgba(115,192,183,0.76)',
            full: 'linear-gradient(180deg, rgba(171,225,172,0.76) 0%, rgba(115,192,183,0.76) 100%)',
        },
    },
};

export const generic = {
    boxShadow: '0 2px 20px 0 rgb(0 0 0 / 12%)',
    borderRadius: {
        xxl: '16em',
        xl: '30px',
        l: '24px',
        md: '10px',
        sm: '3px',
    },
    padding: {
        sm: '.25em',
        m: '.5em',
        l: '1em',
        xl: '1.5em',
        xxl: '2em',
    },
};

export const media = {
    sm: '412px',
};

export const planIdText = /premium|enterprise|npremium|nenterprise|influencer|basic|price_1OdxYZCzTjGY2ZXVcy81rxWJ/;
