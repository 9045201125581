import { combineEpics, ofType } from 'redux-observable';
import { filter, switchMap, mapTo } from 'rxjs/operators';


import BillingApi from 'src/api/billing';

import {
    FETCH_SUBSCRIPTIONS,
    fetchSubscriptionsFulfilled,
    ADD_COUPON,
    addCouponFulfilled,
    addCouponRejected,
    ADD_COUPON_FULFILLED, ADD_COUPON_REJECTED,
    PRICING_PAYMENT,
    pricingPaymentFulfilled,
    pricingPaymentRejected,
    PRICING_PAYMENT_FULFILLED, PRICING_PAYMENT_REJECTED,
    getDiscount,
} from './pricingActions';
import { vipExtrasApi } from '../old/VipExtras/api';
import { onSuccessfullTransactionNotification, onSuccessfullAddCoupon, rejectedCoupon, rejectedPayment } from './components/Notifications';


const fetchSubscriptionsEpic = (action$, store) =>
    action$.pipe(
        ofType(FETCH_SUBSCRIPTIONS),
        filter(() => store.getState().pricing.availableSubscriptions.length === 0),
        switchMap(() => BillingApi.fetchSubscriptions(store.getState().auth.token).then(fetchSubscriptionsFulfilled)),
    );

const checkCoupon = (action$, store) =>
    action$.pipe(
        ofType(ADD_COUPON),
        switchMap(({payload: coupon})=>
            vipExtrasApi
                .fetchCouponDetails(coupon)
                .then(getDiscount)
                .then(addCouponFulfilled)
                .catch(addCouponRejected),
        ),
    );

const makePaymentEpic = (action$, store) =>
    action$.pipe(
        ofType(PRICING_PAYMENT),
        switchMap(({ payload: transactionPayload }) =>
            vipExtrasApi
                .vipExtrasPayment(store.getState().auth.token, transactionPayload)
                .then(pricingPaymentFulfilled)
                .catch(pricingPaymentRejected),
        ),
    );

const onPaymentNotificationEpic = (action$, store) =>
    action$.pipe(
        ofType(PRICING_PAYMENT_FULFILLED),
        mapTo(onSuccessfullTransactionNotification),
    );

const onCouponNotificationEpic = (action$, store) =>
    action$.pipe(
        ofType(ADD_COUPON_FULFILLED),
        mapTo(onSuccessfullAddCoupon),
    );

const rejectedNotificationPayment = (action$, store) =>
    action$.pipe(
        ofType(PRICING_PAYMENT_REJECTED),
        mapTo(rejectedPayment),
    )

const rejectedNotificationCoupon = (action$, store) =>
    action$.pipe(
        ofType(ADD_COUPON_REJECTED),
        mapTo(rejectedCoupon),
    )

export const pricingEpic = combineEpics(fetchSubscriptionsEpic, makePaymentEpic, checkCoupon, onPaymentNotificationEpic, onCouponNotificationEpic, rejectedNotificationCoupon, rejectedNotificationPayment);
